if ($('.js-map-clinic').length) {
  var init = function init() {
      var myMap = new ymaps.Map('map-clinic', {
          center: [59.950772, 30.319828],
          zoom: 10,
          controls: ['zoomControl'],
          behaviors: ['multiTouch']
      }, {
          searchControlProvider: 'yandex#search'
      }),

      MyClusterContentLayout = ymaps.templateLayoutFactory.createClass(
          '<div class="cluster__marker">{{ properties.geoObjects.length }}</div>'
      ),

      
      MyClusterLayout = ymaps.templateLayoutFactory.createClass(
          '<div class="cluster">$[[options.contentLayout]]</div>'
      ),

      myIconLayout = ymaps.templateLayoutFactory.createClass(
          '<div class="marker" data-id="$[id]">'+
              '$[[options.contentLayout]]'+
              '<div class="ballon">'+
                  '<div class="ballon__body">'+
                      '<div class="ballon__name">$[properties.name]</div>'+
                      '<div class="ballon__address">$[properties.city]</div>'+
                  '</div>'+
              '</div>'+
          '</div>'
      ),
      
      myIconContentLayout = ymaps.templateLayoutFactory.createClass(
          '<div class="marker__container"><div class="marker__container-img"></div></div>'
      ),

      objectManager = new ymaps.ObjectManager({
          clusterize: true,
          gridSize: 16384,
          clusterDisableClickZoom: true
      });


      objectManager.objects.options.set({
          hasBalloon : false ,
          hideIconOnBalloonOpen: false,
          iconLayout: myIconLayout,
          iconContentLayout: myIconContentLayout,
          iconShape: {
              type: 'Rectangle',
              coordinates: [
                  [-32 , -82], [32, 0]
              ]
          }
      });

      
      objectManager.clusters.options.set({
          clusterIconLayout: MyClusterLayout,
          clusterIconContentLayout: MyClusterContentLayout,
          hasBalloon : false ,
          iconShape: {
              type: 'Rectangle',
              coordinates: [
                  [-20 , -53], [20, 0]
              ]
          }
      });

      $(".marker").addClass("is-active");

      var address = $(".js-address-contact"),
          time = $(".js-address-time"),
          phone = $(".js-phone-contact"),
          name = $(".js-name-contact"),
          city = $(".js-name-city");



      $('.js-select-address').on('change', function (evt) {
        if ($('.js-select-address').select2('val') != null){
          let numberClinic = $('.js-select').select2('data')[0].id;
            console.log("numberClinic = " , numberClinic);

            if (numberClinic != "all"){
              objectManager.setFilter(function (obj) {
                return obj.id == numberClinic;
              });

              var object = objectManager.objects.getById(numberClinic),
                  objectCoordinate = object.geometry.coordinates;
     
                myMap.setCenter(objectCoordinate , 15);

                $(address).text(object.properties.address);
                $(time).text(object.properties.time);
                $(phone).text(object.properties.phone);
                $(name).text(object.properties.name);
                $(city).text(object.properties.city);
                $(phone).attr("href", "tel:" + object.properties.phone.replace(/[- )(]/g,'')); 

            } else {
                
                objectManager.setFilter(function (obj) {
                    return obj ;
                });

                let object = objectManager.objects.getById(0);
                
                $(address).text(object.properties.address);
                $(time).text(object.properties.time);
                $(phone).text(object.properties.phone);
                $(name).text(object.properties.name);
                $(city).text(object.properties.city);
                $(phone).attr("href", "tel:" + object.properties.phone.replace(/[- )(]/g,''));
          }
        }
      });

    function onObjectEvent (e) {
      var objectId = e.get('objectId'),
      marker;

      if ($(window).width() > 992) {
        if (e.get('type') == 'mouseenter') {
            var objectId = e.get('objectId');

            marker = $(".js-map-clinic .marker[data-id='"+objectId+"']");  
            $(marker).addClass("is-hover");

        } else  if (e.get('type') == 'mouseleave')  {
            $(".js-map-clinic .marker").removeClass("is-hover");
        }
      }

      if (e.get('type') == 'click') {
        console.log("click");
        let objectId = e.get('objectId'),
            marker = $(".js-map-clinic .marker[data-id='"+objectId+"']"),

            obj = objectManager.objects.getById(objectId);
            $(".marker").removeClass("is-active");
            marker.addClass("is-active");
            console.log(obj);
            
            $(address).text(obj.properties.address);
            $(time).text(obj.properties.time);
            $(phone).text(obj.properties.phone);
            $(name).text(obj.properties.name);
            $(city).text(obj.properties.city);
            $(phone).attr("href", "tel:" + obj.properties.phone.replace(/[- )(]/g,''));
      }  
    }


    $.ajax({
        url: "data/clinics.json"
    }).done(function(data) {
        objectManager.add(data);
    });

    myMap.geoObjects.add(objectManager);
   
    objectManager.objects.events.add(['mouseenter', 'mouseleave' , 'click'], onObjectEvent);
  };

  ymaps.ready(init);

};


$( document ).ready(function() {
  $(".marker").addClass("is-active");
  
  $(".map-clinic").addClass("is-hover");
});

ymaps.ready(function(){
  $(".marker").addClass("is-active");
});